<template>
    <div></div>
</template>
<script>
export default {
    head() {
        return {
            meta: [{ hid: 'robots', name: 'robots', content: 'noindex, nofollow' }],
        };
    },
    mounted() {
        if (!this.$device.isIOS()) {
            window.location.href =
                'https://play.google.com/store/apps/details?id=com.dundle.app&referrer=utm_source%3Dwebsite%26utm_medium%3Dhomepage_banner%26utm_term%3Dapp_campaign%26anid%3Dadmob';
        } else {
            window.location.href = 'https://apps.apple.com/app/dundle-prepaid-cards-egifts/id1605958640';
        }
    },
};
</script>
